import { Spin, Breadcrumb, Button, Table, Pagination, Popconfirm } from "antd";
import React, { useState, useEffect } from "react";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actShowLoading, actHideLoading } from "../../../actions/actionLoading";
import { deletePhieuThu, fetchPhieuThu } from "../../../actions/actionPhieuThu";
import {
  addFormData,
  FetchURL,
  openNotificationWithIcon,
} from "../../../util/help";
import SearchComponent from "../../../Component/SearchComponent";
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import axios from "axios";

function PhieuThu() {
  const [state, setState] = useState({ total_page: 1 });
  const [search, setSearch] = useState({ type: "", keyword: "" });

  const isLoading = useSelector((state) => state.isLoading);
  const phieuthu = useSelector((state) => state.phieuthu);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const Fetch = (data) => dispatch(fetchPhieuThu(data));
  const DeletePhieuThu = (RECEIPT_NO) => dispatch(deletePhieuThu(RECEIPT_NO));

  const Delete = (RECEIPT_NO) => {
    var params = {
      RECEIPT_NO: RECEIPT_NO,
    };
    showLoading();
    axios
      .post("payment/receipts/remove", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            DeletePhieuThu(RECEIPT_NO);
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã xóa thành công ${RECEIPT_NO}`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Receipt No",
      dataIndex: "RECEIPT_NO",
      key: "RECEIPT_NO",
    },
    {
      title: "Người Nhập",
      dataIndex: "INPUT_USER",
      key: "INPUT_USER",
    },
    {
      title: "Tên Công Ty",
      dataIndex: "CUST_NAME",
      key: "CUST_NAME",
    },
    {
      render: (text, record) => (
        <>
          <Link to={`/Dasboard/phieuthu/edit/${record.RECEIPT_NO}`}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#faad14",
                borderColor: "#faad14",
                marginRight: 20,
              }}
              icon={<EditOutlined />}
            >
              <span style={{ marginLeft: 8, color: "white" }}>Sửa</span>
            </Button>
          </Link>

          <Popconfirm
            title="Bạn có chắn chắn xóa?"
            onConfirm={() => Delete(record.RECEIPT_NO)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              Xóa
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            style={{ marginLeft: 20 }}
            onClick={() => printPhieuThu(record.RECEIPT_NO)}
          >
            In
          </Button>
        </>
      ),
    },
  ];

  function printPhieuThu(RECEIPT_NO) {
    window.open(
      FetchURL + `print/payment/receipts/type=receipt&receiptno=${RECEIPT_NO}`
    );
  }

  useEffect(() => {
    onChangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.keyword]);

  const onChangePage = (page) => {
    var newPage = 1;
    var url = "";
    if (page) {
      newPage = page;
    }
    if (search.keyword) {
      url = `payment/receipts/search/type=${search.type}&value=${search.keyword}&page=${newPage}`;
    } else {
      url = `payment/receipts/page=${newPage}`;
    }
    showLoading();
    fetch(FetchURL + url)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  function onSearch(values) {
    setSearch({
      type: values.type,
      keyword: values.keyword,
    });
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Phiếu Thu</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Phiếu Thu</Title>
        </div>
        <SearchComponent onSearch={onSearch} name="phieuthu" />
        <Button type="primary" style={{ marginBottom: 20 }}>
          <Link to="/Dasboard/phieuthu/create">Tạo mới</Link>
        </Button>
        <Table
          dataSource={phieuthu}
          columns={columns}
          rowKey={columns[0].key.toString()}
          pagination={false}
          size="small"
        />
        <Pagination
          total={state.total_page}
          defaultCurrent={1}
          onChange={onChangePage}
          style={{ marginTop: 20 }}
        />
      </div>
    </Spin>
  );
}

export default PhieuThu;
