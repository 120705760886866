import React, { Fragment, useState } from "react";
import Title from "antd/lib/typography/Title";
import { Button, Col, Form, message, Row, Select, Transfer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { actShowLoading, actHideLoading } from "../../../actions/actionLoading";
import fileDownload from "js-file-download";

const { Option } = Select;

function ThongKeJobStart() {
  const dispatch = useDispatch();
  const dropdown = useSelector((state) => state.dropdown);
  const isLoading = useSelector((state) => state.isLoading);
  const [form] = Form.useForm();
  const [listJobFromCustomer, setListJobFromCustomer] = useState([]);

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onChangeTran = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log(sourceSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  function onFinishJobOrder(values) {
    if (values.fromdate && values.tojob) {
      window.open(
        window.print(
          `https://api.ihtvn.com/api/v1/print/file/job-start/fromjob=${values.fromjob}&tojob=${values.tojob}`
        )
      );
    } else {
      message.error("Bạn chưa chọn job");
    }
  }

  function excelJobOrder() {
    showLoading();

    var values = form.getFieldsValue();
    var fromdate = undefined;
    var todate = undefined;
    var array_id = [];
    var formData = new FormData();
    if (values.fromdate && values.fromdate) {
      var yearFrom = values.fromdate.slice(0, 4);
      var monthFrom = values.fromdate.slice(5, 7);
      var dayFrom = values.fromdate.slice(8, 10);

      var yearTo = values.todate.slice(0, 4);
      var monthTo = values.todate.slice(5, 7);
      var dayTo = values.todate.slice(8, 10);
      fromdate = yearFrom + monthFrom + dayFrom;
      todate = yearTo + monthTo + dayTo;
    } else {
      hideLoading();
      message.error("Bạn chưa chọn ngày");
    }
    if (values.type === "customer") {
      for (let index = 0; index < values.KEY_JOB.length; index++) {
        var element = values.KEY_JOB[index];
        array_id.push(
          listJobFromCustomer.find((item) => item.key === element).ID
        );
      }
    }

    formData.append("type", values.type);
    formData.append("fromdate", fromdate);
    formData.append("todate", todate);
    for (var i in array_id) {
      formData.append("array_id[]", array_id[i]);
    }
    axios
      .post("export/file/job-start", formData)
      .then((res) => {
        window.open(res.data.url);
        hideLoading();
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  }

  const onFilterJob = () => {
    showLoading();
    var values = form.getFieldsValue();
    var fromdate = undefined;
    var todate = undefined;
    if (values.fromdate && values.fromdate) {
      var yearFrom = values.fromdate.slice(0, 4);
      var monthFrom = values.fromdate.slice(5, 7);
      var dayFrom = values.fromdate.slice(8, 10);

      var yearTo = values.todate.slice(0, 4);
      var monthTo = values.todate.slice(5, 7);
      var dayTo = values.todate.slice(8, 10);
      fromdate = yearFrom + monthFrom + dayFrom;
      todate = yearTo + monthTo + dayTo;
    } else {
      message.error("Bạn chưa chọn ngày");
    }
    axios
      .get(
        `file/job-start/filter-job?custno=${values.CUST_NO}&fromdate=${fromdate}&todate=${todate}`
      )
      .then((res) => {
        var newData = res.data;
        for (let i = 0; i < newData.length; i++) {
          newData[i].key = i.toString();
        }
        setListJobFromCustomer(newData);
        hideLoading();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Title level={4} style={{ color: "#1890ff" }}>
        Thống kê job start
      </Title>
      <Form onFinish={onFinishJobOrder} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Loại Báo Biểu" name="type">
              <Select>
                {/* <Option key="job">Job Order</Option> */}
                <Option key="job">Job Order</Option>
                <Option key="customer">Khách Hàng</Option>
                <Option key="date">Ngày Job</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("type") === "job" ? (
                  <>
                    <Form.Item label="Từ Job" name="fromjob">
                      <Select
                        placeholder="Từ Job"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dropdown.jobPhieuChiTamUng.map((item, index) => {
                          return (
                            <Option key={index} value={item.JOB_NO}>
                              {item.JOB_NO}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Đến Job" name="tojob">
                      <Select
                        placeholder="Đến Job"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dropdown.jobPhieuChiTamUng.map((item, index) => {
                          return (
                            <Option key={index} value={item.JOB_NO}>
                              {item.JOB_NO}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("type") === "customer" ? (
                  <Form.Item label="Mã Khách Hàng" name="CUST_NO">
                    <Select
                      placeholder="Chọn Mã Khách Hàng"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={onChangeKH}
                    >
                      {dropdown.customer.map((item, index) => {
                        return (
                          <Option key={index} value={item.CUST_NO}>
                            {item.CUST_NO + " | " + item.CUST_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label="Từ ngày" name="fromdate">
            <input type="date" style={{ marginRight: 20 }} />
          </Form.Item>
          <Form.Item label="Đến ngày" name="todate">
            <input type="date" />
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("type") === "customer" ? (
                  <>
                    <Row>
                      <Button
                        type="primary"
                        style={{ marginLeft: 20 }}
                        onClick={onFilterJob}
                        loading={isLoading}
                      >
                        Lọc danh sách job
                      </Button>
                    </Row>

                    <Form.Item label="Job No" name="KEY_JOB">
                      <Transfer
                        dataSource={listJobFromCustomer}
                        listStyle={{
                          width: 400,
                          height: 400,
                        }}
                        pagination
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={onChangeTran}
                        onSelectChange={onSelectChange}
                        showSearch
                        filterOption={(input, option) =>
                          option.JOB_NO.toLowerCase().indexOf(
                            input.toLowerCase()
                          ) >= 0
                        }
                        // onScroll={onScroll}
                        render={(item) => `${item.JOB_NO}`}
                      />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 10, width: 100 }}
        >
          Xem
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 10, width: 100 }}
          onClick={() => excelJobOrder()}
          loading={isLoading}
        >
          Xuất Excel
        </Button>
      </Form>
    </Fragment>
  );
}
export default ThongKeJobStart;
