import {
  Spin,
  Breadcrumb,
  Table,
  Form,
  Row,
  Select,
  Col,
  Input,
  Button,
  message,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import axios from "../../../util/api";
import { orderType } from "../../../util/help";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import Axios from "axios";
import { UploadOutlined } from "@ant-design/icons";

export default function DuyetCuocCont() {
  const [data, setData] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [approve, setApprove] = useState([]);

  const [form] = Form.useForm();
  const key = "updatable";

  const columns = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Type",
      dataIndex: "ORDER_TYPE",
      key: "ORDER_TYPE",
      render: (text) => orderType.find((i) => i.type === text).name,
    },
    {
      title: "Description",
      dataIndex: "DESCRIPTION",
      key: "DESCRIPTION",
    },
    {
      title: "Người nhập",
      dataIndex: "INPUT_USER",
      key: "INPUT_USER",
    },
    {
      title: "Người sửa",
      dataIndex: "MODIFY_USER",
      key: "MODIFY_USER",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setApprove(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  function onFinish(values) {
    axios
      .get(
        `file/approved-cont/type=${values.type}&list=${values.list}&year=${values.year}`
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }

  function onApprove() {
    var formData = new FormData();
    formData.append("type", "approved");
    for (var i in approve) {
      console.log(approve[i]);
      formData.append("data[]", approve[i]);
    }
    console.log(formData.get("data[]"));
    // axios
    //   .post("file/approved-cont", formData)
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));
    // onFinish(values);
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportExcel = () => {
    var values = form.getFieldsValue();
    Axios.post(
      "http://123.30.249.224:3001/api/reports-basic/getListCuocCont",
      {
        input_dt: values.year,
      }
    )
      .then((res) => exportToCSV(res.data.data, "cuoc_cont"))
      .catch((err) => console.log(err));
  };

  const schema = {
    ID: {
      // JSON object property name.
      prop: "ID",
      type: String,
    },
    THANH_TOAN_MK: {
      // JSON object property name.
      prop: "THANH_TOAN_MK",
      type: String,
    },
    NOTE: {
      // JSON object property name.
      prop: "NOTE",
      type: String,
    },
  };

  // const readTest = (input) => {
  //   message.loading({ content: "Loading...", key });
  //   readXlsxFile(input.target.files[0], { schema })
  //     .then(({ rows, errors }) => {
  //       Axios.post(
  //         "https://ihtnode.herokuapp.com/api/reports-basic/edit-List-Cuoc-Cont",
  //         rows
  //       );
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       message.success({ content: "Done!", key, duration: 2 });
  //     })
  //     .catch((err) => console.log(err));
  // };

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;
    var formData = new FormData();
    formData.append("file", file);
    try {
      const res = await Axios.post(
        "http://123.30.249.224:3001/api/reports-basic/edit-cuoc-cont",
        formData
      );
      if (res.status === 200) {
        onSuccess(res.message);
      }
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <Spin size="large" spinning={false}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Hồ Sơ</Breadcrumb.Item>
        <Breadcrumb.Item>Duyệt Cược Cont</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Duyệt Cược Cont</Title>
        </div>
        <Form onFinish={onFinish} form={form}>
          <Row>
            <Col>
              <Form.Item label="Type" name="type">
                <Select style={{ width: 150 }} size="small">
                  <Select.Option key="5">Refund Carriers</Select.Option>
                  <Select.Option key="6">Refund Customer</Select.Option>
                  <Select.Option key="7">Refund Agent</Select.Option>
                  <Select.Option key="8">Deposit fix fee</Select.Option>
                  <Select.Option key="C">Deposit fee</Select.Option>
                  <Select.Option key="I">Our Company Pay</Select.Option>
                  <Select.Option key="O">Pay In Advance</Select.Option>
                  <Select.Option key="T">Trucking Fee</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="List" style={{ marginLeft: 10 }} name="list">
                <Select style={{ width: 150 }} size="small">
                  <Select.Option key="pending">Chưa duyệt</Select.Option>
                  <Select.Option key="approved">Đã duyệt</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Năm" style={{ marginLeft: 10 }} name="year">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 20 }}
                  size="small"
                >
                  Thêm
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  size="small"
                  onClick={() => exportExcel()}
                >
                  Xuất excel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              {/* <input  style={{ marginLeft: 20 }} type={'file'} onChange={(input) => readTest(input)} /> */}
              <Upload customRequest={uploadFile}>
                <Button
                  style={{ marginLeft: 10 }}
                  size="small"
                  icon={<UploadOutlined />}
                >
                  Upload file edit
                </Button>
              </Upload>
            </Col>
          </Row>
        </Form>
        <Table
          dataSource={data}
          columns={columns}
          rowKey={columns[0].key.toString()}
          size="small"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
        />
        <Button type="primary" onClick={onApprove}>
          Duyệt
        </Button>
      </div>
    </Spin>
  );
}
