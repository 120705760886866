import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin, Table, Button, Popconfirm, Pagination } from "antd";
import { Link } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  addFormData,
  FetchURL,
  openNotificationWithIcon,
} from "../../../../util/help";
import {
  deletePhieuChi,
  fetchPhieuChi,
} from "../../../../actions/actionPhieuChiTamUng";
import {
  actHideLoading,
  actShowLoading,
} from "../../../../actions/actionLoading";
import axios from "../../../../util/api";
import SearchComponent from "../../../../Component/SearchComponent";

function PhieuChiTamUng() {
  const [state, setState] = useState({ total_page: 1 });
  const [search, setSearch] = useState({ type: "", keyword: "" });

  const isLoading = useSelector((state) => state.isLoading);
  const phieuchitamung = useSelector((state) => state.phieuchitamung);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const Fetch = (data) => dispatch(fetchPhieuChi(data));
  const DeletePhieuChiTamUng = (LENDER_NO) =>
    dispatch(deletePhieuChi(LENDER_NO));

  useEffect(() => {
    onChangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.keyword]);

  const Delete = (LENDER_NO) => {
    var params = {
      LENDER_NO: LENDER_NO,
    };
    showLoading();
    axios
      .post("payment/lender/remove", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            DeletePhieuChiTamUng(LENDER_NO);
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã xóa thành công ${LENDER_NO}`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangePage = (page) => {
    var newPage = 1;
    var url = "";
    if (page) {
      newPage = page;
    }
    if (search.keyword) {
      url = `payment/lender/search/type=${search.type}&value=${search.keyword}&page=${newPage}`;
    } else {
      url = `payment/lender/page=${newPage}`;
    }
    showLoading();
    fetch(FetchURL + url)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const printPhieuChi = (id) => {
    window.open(FetchURL + `print/payment/advance/advance_no=${id}`);
  };

  const columns = [
    {
      title: "Advance No",
      dataIndex: "LENDER_NO",
      key: "LENDER_NO",
    },
    {
      title: "Type",
      dataIndex: "LENDER_NAME",
      key: "LENDER_NAME",
    },
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Người Nhập",
      dataIndex: "INPUT_USER",
      key: "INPUT_USER",
    },
    {
      title: "Người Sửa",
      dataIndex: "MODIFY_USER",
      key: "MODIFY_USER",
    },
    {
      render: (text, record) => (
        <>
          <Link to={`/Dasboard/phieuchitamung/edit/${record.LENDER_NO}`}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#faad14",
                borderColor: "#faad14",
                marginRight: 20,
              }}
              icon={<EditOutlined />}
            >
              <span style={{ marginLeft: 8, color: "white" }}>Sửa</span>
            </Button>
          </Link>
          {(localStorage.getItem("USER_NO") === "PC                  ") |
          (localStorage.getItem("USER_NO") === "QUYNH               ") |
          (localStorage.getItem("USER_NO") === "JENNY               ") ? (
            <Popconfirm
              title="Bạn có chắn chắn xóa?"
              onConfirm={() => Delete(record.LENDER_NO)}
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Xóa
              </Button>
            </Popconfirm>
          ) : null}

          <Button
            style={{ marginLeft: 20 }}
            type="primary"
            icon={<PrinterOutlined />}
            onClick={() => printPhieuChi(record.LENDER_NO)}
          >
            In
          </Button>
        </>
      ),
    },
  ];

  function onSearch(values) {
    setSearch({
      type: values.type,
      keyword: values.keyword,
    });
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Phiếu Chi Tạm Ứng</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Phiếu Chi Tạm Ứng</Title>
        </div>
        <SearchComponent onSearch={onSearch} name="phieuchitamung" />
        <Button type="primary" style={{ marginBottom: 20 }}>
          <Link to="/Dasboard/phieuchitamung/create">Tạo mới</Link>
        </Button>
        <Table
          dataSource={phieuchitamung}
          columns={columns}
          rowKey={columns[0].key.toString()}
          pagination={false}
          size="small"
        />
        <Pagination
          total={state.total_page}
          defaultCurrent={1}
          onChange={onChangePage}
          style={{ marginTop: 20 }}
        />
      </div>
    </Spin>
  );
}

export default PhieuChiTamUng;
