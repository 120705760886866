import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Select,
  Button,
  Typography,
  Checkbox,
} from "antd";
import "./tableEdit.css";
import {
  orderType,
  openNotificationWithIcon,
  convertNumber,
  onKeyDown,
  addFormData,
  handleEnter,
} from "../../../util/help";
import axios from "../../../util/api";
import {  useDispatch } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./fixform.css";
const { Text } = Typography;

const { Option } = Select;

const CreateItemJobBookTau = ({ job_d, id, getDataDetail }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ data: [] });
  const [serNo, setSerNo] = useState("");
  const [checkNick, setCheckNick] = useState(false);

  // const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };

  useEffect(() => {
    var newData = job_d;
    for (var i = 0; i < newData.length; i++) {
      newData[i].key = i;
    }
    setState({ ...state, data: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_d]);

  const edit = (record) => {
    form.setFieldsValue({
      ORDER_TYPE: record.ORDER_TYPE,
      ORDER_TYPE_NAME: record.ORDER_TYPE_NAME,
      DESCRIPTION: record.DESCRIPTION,
      UNIT: record.UNIT,
      QTY: record.QTY,
      PRICE: record.PRICE,
      TAX_NOTE: record.TAX_NOTE,
      SAU_THUE: record.SAU_THUE,
      TAX_AMT: record.TAX_AMT,
      TONG_TIEN: record.TONG_TIEN,
      NOTE: record.NOTE,
      THANH_TOAN_MK:
        record.THANH_TOAN_MK === "N" ? setCheckNick(false) : setCheckNick(true),
    });
    setSerNo(record.SER_NO);
  };

  const getDataField = (record) => {
    var giaTruocThue = form.getFieldsValue().PRICE
      ? form.getFieldsValue().PRICE
      : 0;
    var sl = form.getFieldsValue().QTY;
    var thue = parseInt(form.getFieldsValue().TAX_NOTE);
    if (thue === 5) {
      var giaSauThue = form.getFieldsValue().SAU_THUE;
      var tongtien = sl * giaSauThue;
      var truocThue = giaSauThue - (giaSauThue * 5) / 100;
      var tienThue = giaSauThue - truocThue;
      form.setFieldsValue({
        TAX_AMT: tienThue * sl, //Tiền Thuế
        SAU_THUE: giaSauThue,
        TONG_TIEN: tongtien,
        PRICE: truocThue,
      });
    } else {
      var tienThue = (giaTruocThue * thue) / 100;
      var giaSauThue = giaTruocThue + tienThue;
      var tongTien = sl * giaSauThue;
      form.setFieldsValue({
        TAX_AMT: tienThue * sl, //Tiền Thuế
        SAU_THUE: giaSauThue,
        TONG_TIEN: tongTien,
      });
    }
  };

  const Delete = async (key) => {
    try {
      const newData = [...state.data];
      const index = newData.findIndex((item) => key.key === item.key);

      if (index > -1) {
        const params = {
          JOB_NO: id,
          ORDER_TYPE: newData[index].ORDER_TYPE,
          SER_NO: newData[index].SER_NO,
        };
        showLoading();
        axios
          .post("file/job-order/remove-d", addFormData(params))
          .then((res) => {
            if (res.status === 200) {
              if (res.data.success === true) {
                newData.splice(index, 1);
                setState({ ...state, data: newData });
                hideLoading();
                openNotificationWithIcon("success", res.data.msg);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <EditOutlined onClick={() => edit(record)} />

            <Popconfirm
              title="Bạn có chắn chắn xóa?"
              onConfirm={() => Delete(record)}
            >
              <DeleteOutlined style={{ marginLeft: 10 }} />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "Loại",
      dataIndex: "ORDER_TYPE_NAME",
    },
    {
      title: "Mô tả",
      dataIndex: "DESCRIPTION",
    },
    {
      title: "ĐVT",
      dataIndex: "UNIT",
    },
    {
      title: "SL",
      dataIndex: "QTY",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Giá Trước Thuế",
      dataIndex: "PRICE",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Thuế",
      dataIndex: "TAX_NOTE",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Giá Sau Thuế",
      dataIndex: "SAU_THUE",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Tiền Thuế",
      dataIndex: "TAX_AMT",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Tổng Tiền",
      dataIndex: "TONG_TIEN",
      render: (text) => convertNumber(parseFloat(text ? text : 0)),
    },
    {
      title: "Ghi Chú",
      dataIndex: "NOTE",
    },
    {
      title: "Thanh toán",
      dataIndex: "THANH_TOAN_MK",
      render: (text) => <Checkbox checked={text === "Y" ? true : false} />,
    },
    {
      title: "Người Nhập",
      dataIndex: "INPUT_USER",
    },
    {
      title: "Người Sửa",
      dataIndex: "MODIFY_USER",
    },
  ];

  function onFinish(values) {
    const typeTest = orderType.find((i) => i.name === values.ORDER_TYPE_NAME);
    values.ORDER_TYPE = typeTest.type;
    values.THANH_TOAN_MK = checkNick ? "Y" : "N";
    showLoading();
    if (serNo) {
      values.SER_NO = serNo;
      values.TYPE = "JOB_ORDER_BOAT";
      values.JOB_NO = id;

      values.MODIFY_USER = localStorage.getItem("USER_NO");
      values.REV_TYPE = "Y";
      axios
        .post("file/job-order/edit-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              setField();
              hideLoading();
              openNotificationWithIcon("success", "Chỉnh Sửa Thành Công");
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      values.TYPE = "JOB_ORDER_BOAT";
      values.JOB_NO = id;
      values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios
        .post("file/job-order/add-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              setField();
              hideLoading();
              openNotificationWithIcon("success", "Tạo Thành Công");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }

  const setField = () => {
    form.setFieldsValue({
      ORDER_TYPE: "",
      ORDER_TYPE_NAME: "",
      DESCRIPTION: "",
      UNIT: "",
      QTY: 0,
      PRICE: 0,
      TAX_NOTE: 0,
      SAU_THUE: 0,
      TAX_AMT: 0,
      TONG_TIEN: 0,
      NOTE: "",
      THANH_TOAN_MK: setCheckNick(false),
    });
    setSerNo("");
  };

  function onSelect(values) {
    if (values === "Trucking Fee") {
      form.setFieldsValue({
        DESCRIPTION: "PHÍ KÉO",
      });
    } else if (values === "Refund Customer") {
      form.setFieldsValue({
        DESCRIPTION: "Refund Khách Hàng",
      });
    } else if (values === "Refund Carriers") {
      form.setFieldsValue({
        DESCRIPTION: "Refund Hãng Tàu",
      });
    } else if (values === "Refund Agent") {
      form.setFieldsValue({
        DESCRIPTION: "Refund Đại Lý",
      });
    } else if (values === "Deposit fee") {
      form.setFieldsValue({
        DESCRIPTION: "Cược Cont",
      });
    }
  }

  function onChangeDes(values) {
    if ((values.target.value === "A") | (values.target.value === "a")) {
      form.setFieldsValue({
        DESCRIPTION: "AIR FREIGHT",
      });
    } else if ((values.target.value === "O") | (values.target.value === "o")) {
      form.setFieldsValue({
        DESCRIPTION: "OCEAN FREIGHT",
      });
    } else {
      form.setFieldsValue({
        DESCRIPTION: values.target.value,
      });
    }
  }

  function onKeyUp(value) {
    if (value.key === "Enter" && value.keyCode === 13) {
      form.submit();
    }
  }

  return (
    <Fragment>
      <div className="fix-form font-size: 12px!importan ant-form label">
        <Form onKeyDown={onKeyDown} form={form} onFinish={onFinish}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto auto",
              padding: 10,
            }}
          >
            <Form.Item label="Loại" name="ORDER_TYPE_NAME">
              <Select
                onKeyDown={handleEnter}
                onSelect={onSelect}
                size="small"
                showSearch
              >
                <Option className="fix-option" key="Our Company Pay">
                  Our Company Pay
                </Option>
                <Option className="fix-option" key="Pay In Advance">
                  Pay In Advance
                </Option>
                <Option className="fix-option" key="Trucking Fee">
                  Trucking Fee
                </Option>
                <Option className="fix-option" key="Deposit fee">
                  Cược Cont
                </Option>
                <Option className="fix-option" key="Deposit fix fee">
                  Cược Sửa Chữa Cont
                </Option>
                <Option className="fix-option" key="Refund Customer">
                  Refund Khách Hàng
                </Option>
                <Option className="fix-option" key="Refund Carriers">
                  Refund Hãng Tàu
                </Option>
                <Option className="fix-option" key="Refund Agent">
                  Refund Đại Lý
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label="Mô tả" name="DESCRIPTION">
              <Input
                onKeyDown={handleEnter}
                onChange={onChangeDes}
                size="small"
              />
            </Form.Item>
            <Form.Item label="ĐVT" name="UNIT">
              <Input size="small" onKeyDown={handleEnter} />
            </Form.Item>
            <Form.Item label="SL" name="QTY">
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={getDataField}
                size="small"
                onKeyDown={handleEnter}
              />
            </Form.Item>
            <Form.Item label="Trước thuế" name="PRICE">
              <InputNumber
                onKeyDown={handleEnter}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={getDataField}
                style={{ width: 120 }}
                size="small"
              />
            </Form.Item>
            <Form.Item label="Thuế" name="TAX_NOTE" initialValue={"0"}>
              <Select
                onKeyDown={handleEnter}
                onSelect={getDataField}
                size="small"
              >
                <Option key={"0"}>0</Option>
                <Option key={"5"}>5</Option>
                <Option key={"8"}>8</Option>
                <Option key={"10"}>10</Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.TAX_NOTE !== currentValues.TAX_NOTE
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item label="Sau thuế" name="SAU_THUE">
                    <InputNumber
                      onKeyDown={handleEnter}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      size="small"
                      onChange={getDataField}
                      style={{
                        color: getFieldValue("TAX_NOTE") === "5" ? null : "red",
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item label="Tiền thuế" name="TAX_AMT">
              <InputNumber
                onKeyDown={handleEnter}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={getDataField}
                size="small"
              />
            </Form.Item>
            <Form.Item label="Tổng tiền" name="TONG_TIEN">
              <InputNumber
                onKeyDown={handleEnter}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="small"
                // disabled
                style={{ color: "red", fontWeight: "bold" }}
              />
            </Form.Item>
            <Form.Item label="Ghi Chú" name="NOTE">
              <Input size="small" onKeyDown={handleEnter} />
            </Form.Item>
            {(localStorage.getItem("USER_NO") === "PC                  ") |
            (localStorage.getItem("USER_NO") === "QUYNH               ") |
            (localStorage.getItem("USER_NO") === "JENNY               ") ? (
              <Form.Item label="Duyệt thanh toán" name="THANH_TOAN_MK">
                <Checkbox checked={checkNick} onChange={onCheckboxChange} />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                accessKey="s"
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 20, marginBottom: 10 }}
                onKeyUp={onKeyUp}
              >
                {serNo ? "Lưu" : "Thêm"}
              </Button>
              {serNo ? (
                <Button type="link" onClick={setField}>
                  Cancel
                </Button>
              ) : null}
            </Form.Item>
          </div>
        </Form>

        <Table
          className="ant-table-content"
          dataSource={state.data}
          columns={columns}
          pagination={false}
          bordered
          size="small"
          scroll={{ x: 1100 }}
          summary={(pageData) => {
            var total = 0;
            pageData.forEach(({ TONG_TIEN }) => {
              total += parseFloat(TONG_TIEN ? TONG_TIEN : 0);
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell>Tổng tiền</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text type="danger">{convertNumber(total)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </Fragment>
  );
};

export default CreateItemJobBookTau;
