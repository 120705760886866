import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Button,
  Spin,
  Row,
  Col,
} from "antd";
import "./tableEdit.css";
import {
  openNotificationWithIcon,
  convertNumber,
  addFormData,
} from "../../../../util/help";
import axios from "../../../../util/api";
import { useSelector, useDispatch } from "react-redux";
import {
  actHideLoading,
  actShowLoading,
} from "../../../../actions/actionLoading";
import HotKeys from "react-hot-keys";

const CreatItemPhieuChiTamUng = ({ job_d, id, getDataDetail, saveDetail }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ data: [] });
  const [serNo, setSerNo] = useState("");

  //focus input
  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const [inputTien, setInputTien] = UseFocus();

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  useEffect(() => {
    var newData = job_d;
    for (var i = 0; i < newData.length; i++) {
      newData[i].key = i;
    }
    setState({ ...state, data: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_d]);

  const edit = (record) => {
    form.setFieldsValue({
      LENDER_AMT: record.LENDER_AMT,
      NOTE: record.NOTE,
    });
    setSerNo(record.SER_NO);
  };

  const Delete = async (key) => {
    try {
      const newData = [...state.data];
      const index = newData.findIndex((item) => key.key === item.key);

      if (index > -1) {
        const params = {
          LENDER_NO: id,
          SER_NO: newData[index].SER_NO,
        };
        showLoading();
        axios
          .post("payment/lender/remove-d", addFormData(params))
          .then((res) => {
            if (res.status === 200) {
              if (res.data.success === true) {
                newData.splice(index, 1);
                setState({ ...state, data: newData });
                hideLoading();
                openNotificationWithIcon("success", res.data.message);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Tiền",
      dataIndex: "LENDER_AMT",
      render: (text) => convertNumber(parseFloat(text)),
    },
    {
      title: "Ghi Chú",
      dataIndex: "NOTE",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Button type="link" onClick={() => edit(record)}>
              Edit
            </Button>

            <Popconfirm
              title="Bạn có chắn chắn xóa?"
              onConfirm={() => Delete(record)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const setField = () => {
    form.setFieldsValue({
      LENDER_AMT: "",
      NOTE: "",
    });
    setSerNo("");
  };

  function onFinish(values) {
    values.SER_NO = serNo;
    values.LENDER_NO = id;
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    if (serNo) {
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      showLoading();
      axios
        .post("payment/lender/edit-d", addFormData(values))
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              setSerNo("");
              setField();
              hideLoading();
              openNotificationWithIcon("success", "Chỉnh Sửa Thành Công");
            } else {
              hideLoading();
            }
          }
        })
        .catch((err) => console.log("test"));
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      console.log(values)
      showLoading();
      axios
        .post("payment/lender/add-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              setField();
              hideLoading();
              openNotificationWithIcon("success", "Tạo Thành Công");
            } 
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if(err.response.config.url === "payment/lender/add-d"){
              hideLoading()
            }
          }
        });
    }
  }

  function onKeyUp(value) {
    if (value) {
      if (value.key === "Enter" && value.keyCode === 13) {
        form.submit();
        setInputTien();
      }
    } else {
      form.submit();
      setInputTien();
    }
  }

  function onKeyUp(keyName, e, handle) {
    e.preventDefault();
    console.log("test:onKeyUp", e, handle);
    var values = form.getFieldsValue();
    if (values) {
      onFinish(values);
    }
    saveDetail()
  }

  return (
    <Fragment>
      <HotKeys keyName="ctrl+s" onKeyDown={onKeyUp}>
        <Form onFinish={onFinish} form={form}>
          <Row>
            <Col>
              <Form.Item label="Tiền" name="LENDER_AMT">
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: 200 }}
                  ref={inputTien}
                  size='small'
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Ghi Chú" style={{ marginLeft: 10 }} name="NOTE">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  accessKey="s"
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onKeyUp={onKeyUp}
                  htmlType="submit"
                >
                  {serNo ? "Lưu" : "Thêm"}
                </Button>
                {serNo ? (
                  <Button type="link" onClick={setField}>
                    Cancel
                  </Button>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </HotKeys>
      <Spin size="large" spinning={isLoading}>
        <Table
          dataSource={state.data}
          columns={columns}
          pagination={false}
          size="small"
        />
      </Spin>
    </Fragment>
  );
};

export default CreatItemPhieuChiTamUng;
