import React, { useEffect } from "react";
import { Breadcrumb, Form, Input, Button, Spin } from "antd";
import axios from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useHistory, useParams } from "react-router-dom";
import { addFormData, FetchURL } from "../../../util/help";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

function CreateStaff() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const setField = (data) => {
    form.setFieldsValue({
      PNL_NO: data.PNL_NO,
      PNL_NAME: data.PNL_NAME,
      PNL_NAME_C: data.PNL_NAME_C,
      PNL_ADDRESS: data.PNL_ADDRESS,
      PNL_ID: data.PNL_ID,
      PNL_TEL: data.PNL_TEL,
    });
  };

  useEffect(() => {
    if (id) {
      showLoading();
      fetch(FetchURL + `data-basic/staff-customs/des/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            setField(data.data);
            hideLoading();
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    showLoading();
    if (id) {
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      values.STOP_MK = "Y";
      axios.post("data-basic/staff-customs/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios.post("data-basic/staff-customs/add", addFormData(values)).then((res) => {
        if (res.status === 201) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    }
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Nhân Viên</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Chỉnh Sửa Nhân Viên" : "Tạo Nhân Viên Mới"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
          >
            {id ? (
              <Form.Item label="PNL_NO" name="PNL_NO" hidden>
                <Input />
              </Form.Item>
            ) : null}

            <Form.Item label="Tên nhân viên" name="PNL_NAME">
              <Input />
            </Form.Item>
            <Form.Item label="Tên tiếng hoa" name="PNL_NAME_C">
              <Input />
            </Form.Item>
            <Form.Item label="Địa chỉ" name="PNL_ADDRESS">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Số CMND" name="PNL_ID">
              <Input />
            </Form.Item>
            <Form.Item label="Điện thoại" name="PNL_TEL">
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {id ? "Sửa" : "Tạo mới"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
export default CreateStaff;
