import { Button, Form, Input, Select, Typography, message } from "antd";
import axios from "../../../util/api";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actDropdownCustomer,
  actDropdownPaymentDebit,
} from "../../../actions/actionDropdown";
import { addFormData, FetchURL, formatColumn } from "../../../util/help";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
// import fileDownload from "js-file-download";
import Axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// import readXlsxFile from "read-excel-file";

const { Option } = Select;
const { Title } = Typography;

export default function BaoCaoLoiNhuan() {
  const dropdown = useSelector((state) => state.dropdown);
  const isLoading = useSelector((state) => state.isLoading);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const getListDebitNote = () => dispatch(actDropdownPaymentDebit());
  const getListCustomer = () => dispatch(actDropdownCustomer());
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  useEffect(() => {
    getListDebitNote();
    getListCustomer();
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData, {
      skipHeader: true,
      origin: "A2",
      cellStyles: true,
    });
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }];
    ws["!merges"] = merge;
    ws["A1"] = { h: "<h1>test</h1>" };
    ws["A2"] = { v: "Số Job" };
    ws["B2"] = { v: "Mã KH" };
    ws["C2"] = { v: "Tên Khách Hàng" };
    ws["D2"] = { v: "Ngày Debit" };
    ws["E2"] = { v: "Tiền Thanh Toán" };
    ws["F2"] = { v: "Chi Phí" };
    ws["G2"] = { v: "Lợi Nhuận" };
    var fmt = "#,##0";
    const range = XLSX.utils.decode_range(ws["!ref"]);
    var colNum = XLSX.utils.decode_col("E");
    var colNum2 = XLSX.utils.decode_col("F");
    var colNum3 = XLSX.utils.decode_col("G");
    formatColumn(ws, colNum, range, fmt);
    formatColumn(ws, colNum2, range, fmt);
    formatColumn(ws, colNum3, range, fmt);
    const wb = {
      SheetNames: ["data"],
      Sheets: { data: ws },
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const key = "updatable";

  function onFinish(values) {
    var from = undefined;
    var to = undefined;
    if (values.fromdate & values.todate) {
      var yearFrom = values.fromdate.slice(0, 4);
      var dayFrom = values.fromdate.slice(5, 7);
      var monthFrom = values.fromdate.slice(8, 10);

      var yearTo = values.todate.slice(0, 4);
      var dayTo = values.todate.slice(5, 7);
      var monthTo = values.todate.slice(8, 10);

      from = yearFrom + dayFrom + monthFrom;
      to = yearTo + dayTo + monthTo;
    }

    values.fromdate = from;
    values.todate = to;
    window.open(
      FetchURL +
        `print/payment/profit/type=${values.type}&jobno=${values.jobno}&custno=${values.customer}&fromdate=${values.fromdate}&todate=${values.todate}`
    );
  }

  function onFinishExcel() {
    message.loading({ content: "Loading...", key });
    var values = form.getFieldsValue();
    var from = undefined;
    var to = undefined;

    var yearFrom = values.fromdate.slice(0, 4);
    var dayFrom = values.fromdate.slice(5, 7);
    var monthFrom = values.fromdate.slice(8, 10);

    var yearTo = values.todate.slice(0, 4);
    var dayTo = values.todate.slice(5, 7);
    var monthTo = values.todate.slice(8, 10);

    from = yearFrom + dayFrom + monthFrom;
    to = yearTo + dayTo + monthTo;
    values.fromdate = from;
    values.todate = to;
    if (values.type === "all" || values.type === "customer") {
      var data = {
        CUST_NO: values.customer,
        FROM_DATE: from,
        TO_DATE: to,
        BRANCH_ID: "IHTVN1",
      };
      Axios.post(
        "https://cors-iht.onrender.com/http://123.30.249.224:3001/api/reports-basic/excel-loi-nhuan",
        data
      )
        .then((res) => {
          exportToCSV(res.data.data, `profit-${values.customer} ${from}&${to}`);
          message.success({ content: "Done!", key, duration: 2 });
        })
        .catch((error) => console.log(error));
    } else {
      values.custno = values.customer;
      showLoading();
      axios
        .post("export/payment/profit", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            window.open(res.data.url);
            hideLoading();
          }
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <Fragment>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Title style={{ color: "#1890ff" }}>Thống Kê Lợi Nhuận</Title>
      </div>
      <Form
        onFinish={onFinish}
        size="small"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 6 }}
        form={form}
      >
        <Form.Item label="Loại" name="type" initialValue="all">
          <Select>
            <Option key="all">Tất Cả</Option>
            <Option key="jobno">Theo Job</Option>
            <Option key="customer">Theo Mã Khách Hàng</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Job No" name="jobno">
          <Select showSearch>
            {dropdown.paymentDebit.map((item) => {
              return <Option key={item.JOB_NO}>{item.JOB_NO}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Mã Khách Hàng" name="customer">
          <Select showSearch>
            {dropdown.customer.map((item) => {
              return (
                <Option key={item.CUST_NO}>
                  {item.CUST_NO + " " + item.CUST_NAME}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Từ ngày" name="fromdate">
          <Input type="date" />
        </Form.Item>
        <Form.Item label="Đến ngày" name="todate">
          <Input type="date" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            In
          </Button>
        </Form.Item>
        <Form.Item name="excel">
          <Button
            type="primary"
            onClick={() => onFinishExcel()}
            loading={isLoading}
          >
            Xuất Excel
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
