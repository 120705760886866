import React, { Fragment, useState } from "react";
import Title from "antd/lib/typography/Title";
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Select,
  Transfer,
  Upload,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { actShowLoading, actHideLoading } from "../../../actions/actionLoading";
import { UploadOutlined } from "@ant-design/icons";
import Axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const { Option } = Select;

function InJobOrder() {
  const dispatch = useDispatch();
  const dropdown = useSelector((state) => state.dropdown);
  const isLoading = useSelector((state) => state.isLoading);
  const [form] = Form.useForm();
  const [listJobFromCustomer, setListJobFromCustomer] = useState([]);
  const [dataDate, setDataDate] = useState([]);

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onChangeTran = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log(sourceSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  function onFinishJobOrder(values) {
    if (values.job_no) {
      window.open(
        `https://api.ihtvn.com/api/v1/print/file/job-order?type=job&job_no=${values.job_no}`
      );
    } else {
      message.error("Bạn chưa chọn job");
    }
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  function excelJobOrder() {
    showLoading();

    var values = form.getFieldsValue();
    var fromdate = undefined;
    var todate = undefined;
    var array_id = [];
    var formData = new FormData();
    if (values.fromdate && values.fromdate) {
      var yearFrom = values.fromdate.slice(0, 4);
      var monthFrom = values.fromdate.slice(5, 7);
      var dayFrom = values.fromdate.slice(8, 10);

      var yearTo = values.todate.slice(0, 4);
      var monthTo = values.todate.slice(5, 7);
      var dayTo = values.todate.slice(8, 10);
      fromdate = yearFrom + monthFrom + dayFrom;
      todate = yearTo + monthTo + dayTo;
    } else {
      hideLoading();
      message.error("Bạn chưa chọn ngày");
    }

    if (values.type === "customer" || values.type === "job") {
      for (let index = 0; index < values.KEY_JOB.length; index++) {
        var e = values.KEY_JOB[index];
        array_id.push(listJobFromCustomer.find((item) => item.key === e).ID);
      }
      formData.append("type", values.type);
      formData.append("fromdate", fromdate);
      formData.append("todate", todate);
      for (var i in array_id) {
        formData.append("array_id[]", array_id[i]);
      }
      axios
        .post("export/file/job-order-new", formData)
        .then((res) => {
          // fileDownload(res.data.url, res.data.url.slice(42, 82));
          window.open(res.data.url);
          hideLoading();
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    } else if (values.type === "date") {
      var data = {
        branch_id: "IHTVN1",
        cust_type: "1",
        fromdate: fromdate,
        todate: todate,
      };
      axios
        .post(
          "http://123.30.249.224:3001/api/reports-basic/printjoborder",
          data
        )
        .then((res) => {
          exportToCSV(res.data.data, res.data.fileName);
          hideLoading();
        })
        .catch((err) => console.log(err));
    }
  }

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;
    var formData = new FormData();
    formData.append("file", file);
    try {
      const res = await Axios.post(
        "https://api.ihtvn.com/api/v1/import/job-order",
        formData
      );
      if (res.status === 200) {
        onSuccess("Ok");
      }
    } catch (err) {
      console.log("Eroor: ", err);
      onError({ err });
    }
  };

  const onFilterJob = () => {
    showLoading();
    var values = form.getFieldsValue();
    var fromdate = undefined;
    var todate = undefined;
    if (values.fromdate && values.fromdate) {
      var yearFrom = values.fromdate.slice(0, 4);
      var monthFrom = values.fromdate.slice(5, 7);
      var dayFrom = values.fromdate.slice(8, 10);

      var yearTo = values.todate.slice(0, 4);
      var monthTo = values.todate.slice(5, 7);
      var dayTo = values.todate.slice(8, 10);
      fromdate = yearFrom + monthFrom + dayFrom;
      todate = yearTo + monthTo + dayTo;
    } else {
      message.error("Bạn chưa chọn ngày");
    }
    axios
      .get(
        `file/job-order/filter-job?custno=${values.CUST_NO}&fromdate=${fromdate}&todate=${todate}`
      )
      .then((res) => {
        var newData = res.data;
        for (let i = 0; i < newData.length; i++) {
          newData[i].key = i.toString();
        }
        setListJobFromCustomer(newData);
        hideLoading();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Title level={4} style={{ color: "#1890ff" }}>
        In Job Order
      </Title>
      <Form onFinish={onFinishJobOrder} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Loại Báo Biểu" name="type">
              <Select>
                <Option key="job">Job Order</Option>
                <Option key="customer">Khách Hàng</Option>
                <Option key="date">Ngày Job</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("type") === "job" ? (
                  <Form.Item label="Mã Job" name="job_no">
                    <Select
                      placeholder="Chọn Job"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dropdown.jobOrder.map((item, index) => {
                        return (
                          <Option key={index} value={item.JOB_NO}>
                            {item.JOB_NO}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("type") === "customer" ? (
                  <Form.Item label="Mã Khách Hàng" name="CUST_NO">
                    <Select
                      placeholder="Chọn Mã Khách Hàng"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={onChangeKH}
                    >
                      {dropdown.customer.map((item, index) => {
                        return (
                          <Option key={index} value={item.CUST_NO}>
                            {item.CUST_NO + " | " + item.CUST_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label="Từ ngày" name="fromdate">
            <input type="date" style={{ marginRight: 20 }} />
          </Form.Item>
          <Form.Item label="Đến ngày" name="todate">
            <input type="date" />
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("type") === "customer" ? (
                  <>
                    <Row>
                      <Button
                        type="primary"
                        style={{ marginLeft: 20 }}
                        onClick={onFilterJob}
                        loading={isLoading}
                      >
                        Lọc danh sách job
                      </Button>
                    </Row>

                    <Form.Item label="Job No" name="KEY_JOB">
                      <Transfer
                        dataSource={listJobFromCustomer}
                        listStyle={{
                          width: 400,
                          height: 400,
                        }}
                        pagination
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={onChangeTran}
                        onSelectChange={onSelectChange}
                        showSearch
                        filterOption={(input, option) =>
                          option.JOB_NO.toLowerCase().indexOf(
                            input.toLowerCase()
                          ) >= 0
                        }
                        // onScroll={onScroll}
                        render={(item) => `${item.JOB_NO}`}
                      />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 10, width: 100 }}
        >
          Xem
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 10, width: 100 }}
          onClick={() => excelJobOrder()}
          loading={isLoading}
        >
          Xuất Excel
        </Button>
        <Upload customRequest={uploadFile}>
          <Button style={{ marginLeft: 10 }} icon={<UploadOutlined />}>
            Upload file excel
          </Button>
        </Upload>
      </Form>
    </Fragment>
  );
}
export default InJobOrder;
