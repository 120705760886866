import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import axios from "axios";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { addFormData } from "../../../util/help";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;
export default function ThongKeSoJob() {
  const dropdown = useSelector((state) => state.dropdown);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  function onFinish(values) {
    var yearFrom = values.fromdate.slice(0, 4);
    var dayFrom = values.fromdate.slice(5, 7);
    var monthFrom = values.fromdate.slice(8, 10);

    var yearTo = values.todate.slice(0, 4);
    var dayTo = values.todate.slice(5, 7);
    var monthTo = values.todate.slice(8, 10);

    var from = yearFrom + dayFrom + monthFrom;
    var to = yearTo + dayTo + monthTo;
    window.open(
      `https://api.ihtvn.com/api/v1/print/payment/job-monthly/type=${values.type}&custno=${values.custno}&fromdate=${from}&todate=${to}`
    );
  }

  function exportExcel() {
    var values = form.getFieldsValue();
    var yearFrom = values.fromdate.slice(0, 4);
    var dayFrom = values.fromdate.slice(5, 7);
    var monthFrom = values.fromdate.slice(8, 10);

    var yearTo = values.todate.slice(0, 4);
    var dayTo = values.todate.slice(5, 7);
    var monthTo = values.todate.slice(8, 10);

    var from = yearFrom + dayFrom + monthFrom;
    var to = yearTo + dayTo + monthTo;
    values.fromdate = from;
    values.todate = to;
    axios
      .post("export/payment/job-monthly",addFormData(values))
      .then((res) => {
          window.open(res.data.url);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Fragment>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Title style={{ color: "#1890ff" }}>Thống Kê Số Job Trong Tháng</Title>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        size="small"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 6 }}
      >
        <Form.Item label="Loại" name="type" initialValue="job_start">
          <Select>
            <Option key="job_start">Job Start</Option>
            <Option key="job_order">Job Order</Option>
            <Option key="debit_note">Debit Note</Option>
            {localStorage.getItem("USER_NO") === "PC                  " ||
            localStorage.getItem("USER_NO") === "QUYNH               " ? (
              <Option key="job_pay">Thống kê giải chi</Option>
            ) : null}
          </Select>
        </Form.Item>
        <Form.Item label="Customer" name="custno">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dropdown.customer.map((item) => {
              return (
                <Option key={item.CUST_NO}>
                  {item.CUST_NO + " | " + item.CUST_NAME}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Từ Ngày" name="fromdate">
          <Input type="date" />
        </Form.Item>
        <Form.Item label="Đến Ngày" name="todate">
          <Input type="date" />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                In
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => exportExcel()}
              style={{ marginLeft: 20 }}
            >
              Xuất Excel
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}
