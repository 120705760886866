import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Form,
  Input,
  Spin,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Collapse,
  Table,
} from "antd";
import axios from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useHistory, useParams } from "react-router-dom";
import {
  actDropdownCustomer,
  actDropdownStaff,
  actListJobNotCreatedOrder,
} from "../../../actions/actionDropdown";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  handleEnter,
  openNotificationWithIcon,
} from "../../../util/help";
import CreatItemJobOrder from "./CreatItemJobOrder";
import SearchComponent from "../../../Component/SearchComponent";

const { Option } = Select;

function CreateJobOrder() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const [dataTest, setDataTest] = useState([]);
  const [search, setSearch] = useState("");

  const [job_d, setJob_d] = useState([]);

  const isLoading = useSelector((state) => state.isLoading);
  const dropdown = useSelector((state) => state.dropdown);
  const taojoborder = useSelector((state) => state.taojoborder);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const getDropDownCustomer = () => dispatch(actDropdownCustomer());
  const getDropDownStaff = () => dispatch(actDropdownStaff());
  const getDropDownListJobNotCreatedOrder = () =>
    dispatch(actListJobNotCreatedOrder());

  const setField = (data) => {
    form.setFieldsValue({
      JOB_NO: data.JOB_NO,
      CUST_NAME: data.CUST_NAME,
      CHK_ML: data.CHK_ML === "N" ? false : true,
      CHK_DATE: convertDateTime(data.CHK_DATE),
      CUST_NO: data.CUST_NO,
      BILL_NO: data.BILL_NO,
      ORDER_DATE: convertDateTime(data.ORDER_DATE),
      ORDER_FROM: data.ORDER_FROM,
      ORDER_TO: data.ORDER_TO,
      CUSTOMS_NO: data.CUSTOMS_NO,
      CUSTOMS_DATE: convertDateTime(data.CUSTOMS_DATE),
      NW: data.NW,
      GW: data.GW,
      POL: data.POL,
      POD: data.POD,
      QTY: data.QTY,
      ETD_ETA: convertDateTime(data.ETD_ETA),
      CONTAINER_QTY: data.CONTAINER_QTY,
      CONTAINER_NO: data.CONTAINER_NO,
      INVOICE_NO: data.INVOICE_NO,
      NOTE: data.NOTE,
      CONSIGNEE: data.CONSIGNEE,
      SHIPPER: data.SHIPPER,
      PO_NO: data.PO_NO,
    });
  };

  const getDataDetail = (id) => {
    fetch(FetchURL + `file/job-order/des/job=${id}&type=JOB_ORDER`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setJob_d(data.job_d);
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch(FetchURL + "file/job-order/take=50")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setDataTest(data.data);
          // Fetch(data.data);
          // setState({ total_page: data.total_page });
          // hideLoading();
        }
      })
      .catch((err) => console.log(err));
    getDropDownCustomer();
    getDropDownStaff();
    getDropDownListJobNotCreatedOrder();
    if (id) {
      showLoading();
      getDataDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    showLoading();
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    if (id) {
      values.TYPE = "JOB_ORDER";
      values.JOB_NO = id;
      values.MODIFY_USER = localStorage.getItem("USER_NO");

      axios.post("file/job-order/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            openNotificationWithIcon("success", `Sửa Thành Công ${id}`);
            hideLoading();
            // history.goBack();
          }
        }
      });
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios.post("file/job-order/add", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            openNotificationWithIcon("success", "Tạo Thành Công");
            hideLoading();
            // history.goBack();
            history.push(`/Dasboard/taojoborder/edit/${res.data.data.JOB_NO}`);
          }
        }
      });
    }
  };

  const onCahngSelectJob = (values) => {
    fetch(FetchURL + `file/job-start/des/${values}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  function onChangeEdit(value) {
    id = value;
    showLoading()
    fetch(FetchURL + `file/job-order/des/job=${value}&type=JOB_ORDER`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setJob_d(data.job_d);
          setField(data.data);
          history.push(`/Dasboard/taojoborder/edit/${value}`);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  }

  const { Panel } = Collapse;

  function onChange(value) {
    var url =''
    if(value) {
      url = `file/job-order/search/type=${search ? search : 'job_no'}&value=${value}`
    } else {
      url = 'file/job-order/take=50'
    }
    showLoading();
    fetch(FetchURL + url)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          onChangeEdit(data.data[0].JOB_NO)
          setDataTest(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  }


  const columnSearch = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
  ];

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Hồ Sơ</Breadcrumb.Item>
        <Breadcrumb.Item>Tạo Job Order</Breadcrumb.Item>
        {id ? `Chỉnh Sửa Job Order / ` : "Tạo Job Order Mới"}
        <Select
          placeholder="Chọn"
          bordered={false}
          size="small"
          onChange={(value) => (value ? setSearch(value) : setSearch("job_no"))}
          defaultValue='job_no'
        >
          <Option value="job_no">Job No</Option>
          <Option value="cust_no">Customer No</Option>
          <Option value="cust_name">Customer Name</Option>
          <Option value="customs_no">Custom No</Option>
          <Option value="container_no">Container No</Option>
          <Option value="note">Note</Option>
          <Option value="bill_no">Bill No</Option>
          <Option value="document_staff">Staff</Option>
          <Option value="po_no">P/O</Option>
        </Select>
        <Input.Search
          size="small"
          defaultValue={id}
          bordered={false}
          style={{ width: 150, marginTop: 2 }}
          onSearch={onChange}
        />
      </Breadcrumb>
      <Spin size="large" spinning={isLoading}>
      <div style={{ float: "left", width: "15%" }}>
        <Table
          dataSource={dataTest}
          size="small"
          pagination={false}
          scroll={{ y: 680 }}
          columns={columnSearch}
          style={{ cursor: "pointer" }}
          pagination={false}
          rowKey={columnSearch[0].key.toString()}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onChangeEdit(record.JOB_NO);
              },            
            };
          }}
        />
      </div>
      <div style={{ float: "right", width: "83%" }}>
        <Row gutter={24} style={{ flexFlow: "unset" }}>
          <Col span={24}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header={`Thông tin job ${id ? id : ""}`} key="1">
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <Form
                      name="dynamic_form_nest_item"
                      onFinish={onFinish}
                      autoComplete="off"
                      form={form}
                    >
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={8}>
                          <Form.Item label="Job No" name="JOB_NO">
                            <Select
                              placeholder="Chọn Job"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              size="small"
                              onChange={onCahngSelectJob}
                              onKeyDown={handleEnter}
                            >
                              {dropdown.jobNotCreatedOrder.map(
                                (item, index) => {
                                  return (
                                    <Option key={index} value={item.JOB_NO}>
                                      {item.JOB_NO}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Order Date" name="ORDER_DATE">
                            <Input
                              disabled
                              style={{ color: "red", fontWeight: "bold" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={2}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <Form.Item
                            label="Duyệt"
                            name="CHK_MK"
                            valuePropName="checked"
                          >
                            <Checkbox disabled />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label="Approve Date" name="CHK_DATE">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={6}>
                          <Form.Item label="Customer No" name="CUST_NO">
                            <Input
                              disabled
                              style={{ color: "red", fontWeight: "bold" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={18}>
                          <Form.Item name="CUST_NAME">
                            <Input
                              disabled
                              style={{ color: "red", fontWeight: "bold" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={8}>
                          <Form.Item label="Consignee" name="CONSIGNEE">
                            <Input onKeyDown={handleEnter} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Shipper" name="SHIPPER">
                            <Input onKeyDown={handleEnter} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Bill No" name="BILL_NO">
                            <Input onKeyDown={handleEnter} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={8}>
                          <Form.Item label="Order From" name="ORDER_FROM">
                            <Input onKeyDown={handleEnter} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Order To" name="ORDER_TO">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Số tờ khai" name="CUSTOMS_NO">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={8}>
                          <Form.Item label="Cust Date" name="CUSTOMS_DATE">
                            <Input type="date" onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Invoice No" name="INVOICE_NO">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Container No" name="CONTAINER_NO">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={6}>
                          <Form.Item label="Container Qty" name="CONTAINER_QTY">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="Qty" name="QTY">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="NW" name="NW">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="GW" name="GW">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="POL" name="POL">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={8}>
                          <Form.Item label="POD" name="POD">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="ETD/ETA" name="ETD_ETA">
                            <Input type="date" onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="P/O No" name="PO_NO">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                        <Col span={24}>
                          <Form.Item label="Ghi Chú" name="NOTE">
                            <Input onKeyDown={handleEnter}/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "-1.5em" }}>
                        <Col>
                          <Form.Item>
                            <Button
                              accessKey="s"
                              type="primary"
                              htmlType="submit"
                            >
                              {id ? "Lưu" : "Tạo mới"}
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          {id ? (
                            <Button
                              type="primary"
                              style={{ marginLeft: 20 }}
                              onClick={() =>
                                history.push("/Dasboard/taojoborder/create")
                              }
                            >
                              Tạo mới
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                    </Form>
                  </div>
              </Panel>
            </Collapse>

            {id ? (
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360, marginTop: 40 }}
              >
                <CreatItemJobOrder
                  job_d={job_d}
                  id={id}
                  getDataDetail={getDataDetail}
                  onFinishDetailJob={onFinish}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
      </Spin>
    </Fragment>
  );
}
export default CreateJobOrder;
