import { Breadcrumb, Spin, Tabs, Space, Button, Table, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { convertDateTime, FetchURL } from "../../../util/help";
import {
  fetchApproveJobOrder,
  fetchPendingJobOrder,
} from "../../../actions/actionDuỵetJobOrder";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

function ChiPhiCuocTauCont() {
  const [state, setState] = useState({ total_page: 1 });

  const isLoading = useSelector((state) => state.isLoading);
  const duyetjoborder = useSelector((state) => state.duyetjoborder);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const Fetch = (data) => dispatch(fetchPendingJobOrder(data));
  const Fetch2 = (data) => dispatch(fetchApproveJobOrder(data));

  useEffect(() => {
    onChangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = (page) => {
    var newPage = 1;
    if (page) {
      newPage = page;
    }
    showLoading();
    fetch(FetchURL + `payment/boat-fee/list-boat-month-m/page=${newPage}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangePageApprove = (page) => {
    var newPage = 1;
    if (page) {
      newPage = page;
    }
    showLoading();
    fetch(FetchURL + `payment/boat-fee/list-fee-month-m/page=${newPage}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch2(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "BOAT_FEE_MONTH",
      key: "BOAT_FEE_MONTH",
      render: (text) => convertDateTime(text),
    },
    {
      title: "",
      key: "BOAT_FEE_MONTH",
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/Dasboard/chiphicuoctaucont/edit/${record.FEE_TYPE}&${record.BOAT_FEE_MONTH}`}>
            <Button
              type="primary"
              // onClick={() => {
              //   destBoatCont(record.FEE_TYPE, record.BOAT_FEE_MONTH);
              //   openModalEdit();
              // }}
            >
              Xem
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Chi Phí Cược Tàu Cont</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Chi Phí Cược Tàu Cont</Title>
        </div>

        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"large"}
          onTabClick={(e) =>
            e === "2" ? onChangePageApprove() : onChangePage()
          }
        >
          <TabPane tab="Book Tàu" key="1">
            <Table
              dataSource={duyetjoborder}
              columns={columns}
              rowKey={columns[0].key.toString()}
              pagination={false}
              size='small'
            />
            <Pagination
              total={state.total_page}
              defaultCurrent={1}
              onChange={onChangePage}
              style={{ marginTop: 20 }}
            />
          </TabPane>
          <TabPane tab="Cược Cont" key="2">
            <Table
              dataSource={duyetjoborder}
              columns={columns}
              rowKey={columns[0].key.toString()}
              pagination={false}
              size='small'
            />{" "}
            <Pagination
              total={state.total_page}
              defaultCurrent={1}
              onChange={onChangePageApprove}
              style={{ marginTop: 20 }}
            />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
}

export default ChiPhiCuocTauCont;
